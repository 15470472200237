<template>
  <div>
    <div class="page-wrapper-xl">
      <div class="nexcard p-4">
        <h3 class="modal-card__title text-center">
          {{ "Trial Document" }}
        </h3>

        <div class="px-5 py-4">
          <div class="card p-3">
            <h5 class="mb-0">
              <b>{{ "Upload Trial Transcript Document" }}</b>
            </h5>

            <div class="row mt-4 mb-3">
              <div class="col">
                <input
                  class="form-control"
                  type="file"
                  id="formFile"
                  ref="file_Judgement"
                  accept=".pdf"
                  @change="handleFileChange"
                />
                <small class="font-12 d-flex align-items-center">
                  <b-icon icon="info-circle" />
                  &nbsp;Please upload PDF file only
                </small>
              </div>

              <div class="col">
                <input
                  class="form-control"
                  type="text"
                  id="fileName"
                  placeholder="File Name"
                  v-model="selectedFileName"
                />
                <small class="font-12 d-flex align-items-center">
                  <b-icon icon="info-circle" />
                  &nbsp;Please rename your file
                </small>
              </div>
            </div>

            <!-- start of Witness Client => Client's Witness -->
            <div class="row mt-3">
              <div class="col-9 d-flex align-items-center">
                <b>Number of Client's Witness:</b>
                <span
                  id="tooltip-target-9"
                  class="span_badge material-icons-outlined cursor-pointer"
                >
                  help_outline
                </span>
                <b-tooltip
                  target="tooltip-target-9"
                  trigger="hover"
                  placement="righttop"
                  variant="secondary"
                  custom-class="LESInputTooltip"
                >
                  To generate Witness analysis
                </b-tooltip>
              </div>

              <div class="col d-flex">
                <b-form-input
                  v-model="wc_number"
                  placeholder="#"
                  autocorrect="off"
                  autocomplete="off"
                  v-validate="'required'"
                  type="number"
                  name="number"
                  @keyup.enter="
                    (event) => {
                      event.target.blur();
                    }
                  "
                  @blur="updateArr('wc')"
                  disabled
                ></b-form-input>
                <b-button class="increment w-30 mx-1" @click="increment('wc')">+</b-button>
                <b-button class="decrement w-30" @click="decrement('wc')">-</b-button>
              </div>

              <div class="col-12 text-danger d-flex justify-content-end">
                <small v-if="wc_number <= 0">
                  Leave the field empty if there is no Client's Witness
                </small>
                <small v-if="wc_number > 10">
                  Only 10 Client's Witnesses are allowed
                </small>
              </div>
            </div>
            <div class="accordion mt-2" role="tablist">
              <b-card
                no-body
                class="mb-1"
                v-for="(item, index) in witness_client"
                :key="index"
              >
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button
                    v-b-toggle="'witness-client-' + index"
                    class="accordion-header d-flex align-items-center py-0"
                  >
                    <div class="name">
                      {{
                        item.name == ""
                          ? "Client's Witness " + (index + 1)
                          : index + 1 + ". " + item.name
                      }}
                    </div>

                    <div
                      class="redbtn btn"
                      @click="clearArrData('wc', index)"
                      v-if="item.statement !== '' || item.name !== ''"
                      v-b-tooltip.hover
                      title="Clear"
                      v-b-tooltip.noninteractive
                    >
                      <i class="bi bi-eraser-fill"></i>
                    </div>

                    <div
                      class="redbtn btn"
                      @click="removeArrData('wc', index)"
                      v-if="witness_client.length > 1 || witness_client.name"
                      v-b-tooltip.hover
                      title="Delete"
                      v-b-tooltip.noninteractive
                    >
                      <i class="bi bi-trash-fill"></i>
                    </div>

                    <span class="material-icons-outlined arrow-icon">
                      expand_less
                    </span>
                  </b-button>
                </b-card-header>
                <b-collapse
                  :id="'witness-client-' + index"
                  :visible="index === finalWC"
                  accordion="witness-client-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-form-input
                      v-model.trim="item.name"
                      placeholder="Name"
                      autocorrect="off"
                      autocomplete="off"
                      type="text"
                    ></b-form-input>

                    <div>
                      <b-form-textarea
                        id="textarea"
                        class="mt-3"
                        placeholder="Statement"
                        autocorrect="off"
                        autocomplete="off"
                        rows="3"
                        max-rows="3"
                        type="text"
                        :name="'witness_client_statement_' + index"
                        v-model.trim="item.statement"
                      ></b-form-textarea>
                      <small class="text-muted float-right count_length">
                        {{ getWordCount(item.statement) }} / 1000
                      </small>
                      <span
                        class="text-danger text-sm"
                        v-show="getWordCount(item.statement) > 1000"
                      >
                        {{ "Should not be more than 1000 words" }}
                      </span>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
            <!-- end of Witness Client -->

            <!-- start of Witness Opposing => Adverse Witness -->
            <div class="row mt-4">
              <div class="col-9 d-flex align-items-center">
                <b>Number of Adverse Witness: </b>
                <span
                  id="tooltip-target-10"
                  class="span_badge material-icons-outlined cursor-pointer"
                >
                  help_outline
                </span>
                <b-tooltip
                  target="tooltip-target-10"
                  trigger="hover"
                  placement="righttop"
                  variant="secondary"
                  custom-class="LESInputTooltip"
                >
                  To generate Adverse Witness analysis
                </b-tooltip>
              </div>
              <div class="col d-flex">
                <b-form-input
                  v-model="wo_number"
                  placeholder="#"
                  autocorrect="off"
                  autocomplete="off"
                  v-validate="'required'"
                  type="number"
                  name="number"
                  @keyup.enter="
                    (e) => {
                      e.target.blur();
                    }
                  "
                  @blur="updateArr('wo')"
                  disabled
                ></b-form-input>
                <b-button class="increment w-30 mx-1" @click="increment('wo')">+</b-button>
                <b-button class="decrement w-30" @click="decrement('wo')">-</b-button>
              </div>

              <div class="col-12 text-danger d-flex justify-content-end">
                <small v-if="wo_number <= 0">
                  Leave the field empty if there is no Adverse Witness
                </small>
                <small v-if="wo_number > 10">
                  Only 10 Adverse Witnesses are allowed
                </small>
              </div>
            </div>
            <div class="accordion mt-2" role="tablist">
              <b-card
                no-body
                class="mb-1"
                v-for="(item, index) in witness_opposing"
                :key="index"
              >
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button
                    v-b-toggle="'witness-opposing-' + index"
                    class="accordion-header d-flex align-items-center py-0"
                  >
                    <div class="name">
                      {{
                        item.name == ""
                          ? "Adverse Witness " + (index + 1)
                          : index + 1 + ". " + item.name
                      }}
                    </div>

                    <div
                      class="btn redbtn"
                      @click="clearArrData('wo', index)"
                      v-if="item.statement !== '' || item.name !== ''"
                      v-b-tooltip.hover
                      title="Clear"
                      v-b-tooltip.noninteractive
                    >
                      <i class="bi bi-eraser-fill"></i>
                    </div>

                    <div
                      class="btn redbtn"
                      @click="removeArrData('wo', index)"
                      v-if="witness_opposing.length > 1 || witness_opposing.name"
                      v-b-tooltip.hover
                      title="Delete"
                      v-b-tooltip.noninteractive
                    >
                      <i class="bi bi-trash-fill"></i>
                    </div>

                    <span class="material-icons-outlined arrow-icon">
                      expand_less
                    </span>
                  </b-button>
                </b-card-header>
                <b-collapse
                  :id="'witness-opposing-' + index"
                  :visible="index == finalWO"
                  accordion="witness-opposing-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-form-input
                      v-model.trim="item.name"
                      placeholder="Name"
                      autocorrect="off"
                      autocomplete="off"
                      type="text"
                    ></b-form-input>

                    <div>
                      <b-form-textarea
                        id="textarea"
                        class="mt-3"
                        placeholder="Statement"
                        autocorrect="off"
                        autocomplete="off"
                        rows="3"
                        max-rows="3"
                        type="text"
                        :name="'witness_opposing_statement_' + index"
                        v-model.trim="item.statement"
                      ></b-form-textarea>
                      <small class="text-muted float-right count_length">
                        {{ getWordCount(item.statement) }} / 1000
                      </small>

                      <span
                        class="text-danger text-sm"
                        v-show="getWordCount(item.statement) > 1000"
                      >
                        {{ "Should not be more than 1000 words" }}
                      </span>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
            <!-- end of Witness Opposing -->

            <!-- start of Evidences -->
            <div class="mt-4 d-flex align-items-center">
              <b>Client's Evidences</b>
              <span
                id="tooltip-target-11"
                class="span_badge material-icons-outlined cursor-pointer"
              >
                help_outline
              </span>
              <b-tooltip
                target="tooltip-target-11"
                trigger="hover"
                placement="righttop"
                variant="secondary"
                custom-class="LESInputTooltip"
              >
                To generate Evidence Analysis
              </b-tooltip>
            </div>
            <input
              id="cevidence"
              ref="cevidence"
              class="form-control mt-2"
              type="file"
              multiple
              accept=".png,.jpeg,.jpg,.pdf"
              data-max-files="10"
              @change="uploadEvidence($event, 'ce')"
            />
            <small class="font-12 text-center d-flex align-items-center">
              <b-icon icon="info-circle" class="mx-1"/>
              Please upload .pdf, .png, .jpeg, .jpg file only (maximum 10 files)
            </small>

            <div class=" mt-4 d-flex align-items-center">
              <b>Adverse Evidences</b>
              <span
                id="tooltip-target-12"
                class="span_badge material-icons-outlined cursor-pointer"
              >
                help_outline
              </span>
              <b-tooltip
                target="tooltip-target-12"
                trigger="hover"
                placement="righttop"
                variant="secondary"
                custom-class="LESInputTooltip"
              >
                To generate Adverse Evidence Analysis
              </b-tooltip>
            </div>
            <input
              id="aevidence"
              ref="aevidence"
              class="form-control mt-2"
              type="file"
              multiple
              accept=".png,.jpeg,.jpg,.pdf"
              data-max-files="10"
              @change="uploadEvidence($event, 'ae')"
            />
            <small class="font-12 text-center d-flex align-items-center">
              <b-icon icon="info-circle" class="mx-1"/>
              Please upload .pdf, .png, .jpeg, .jpg file only (maximum 10 files)
            </small>
            <!-- end of Evidences -->

            <div>
              <button
                id="buttonFile"
                class="btn btn-primary float-right"
                 @click="handleFileUpload"
              >
                <div class="row">
                  <div class="col-1">
                    <span class="material-icons-outlined">file_upload</span>
                  </div>
                  <div class="col">
                    {{ "Upload File" }}
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NewTrialForm from "@/store/LES.js";
import { getWordCount, validateInput } from '../../store/utils';

/* eslint-disable */
export default {
  data() {
    return {
      file_in_upload: false,
      selectedFile: null,
      selectedFileName: "",

      dataKey: {
        wo: {
          arr: "witness_opposing",
          num: "wo_number",
        },
        wc: {
          arr: "witness_client",
          num: "wc_number",
        },
      },

      wo_number: 1,
      wc_number: 1,
      witness_opposing: [{ name: "", statement: "" }],
      witness_client: [{ name: "", statement: "" }],
    };
  },

  computed: {
    finalWO() {
      return this.witness_opposing.length - 1;
    },
    finalWC() {
      return this.witness_client.length - 1;
    },
  },

  methods: {
    getWordCount(text) { return getWordCount(text); },
    toastError(flag) {
      const messages = {
        "one_value":"At least one field (witness_client, witness_opposing, trial_doc, or evidence) must have a value.",
        "witness_client": "Please provide Client's Witness",
        "witness_opposing": "Please provide Adverse Witness",
        "file": "Please upload a valid file (PDF format required).",
        "max-evidence": "You can upload a maximum of 10 evidence files.",
        "evidence": "Please upload valid evidence files (PNG, JPEG, JPG, or PDF format required)."
      };
      this.$toast.error(messages[flag]);
    },
    validateFileType(file) {
      const validTypes = ["application/pdf", "image/png", "image/jpeg"];
      return validTypes.includes(file.type);
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.selectedFile = file;
        this.selectedFileName = file.name;
      } else {
        this.selectedFile = null;
        this.selectedFileName = "";
      }
    },

    clearArrData(flag, index) {
      this.$set(
        this[this.dataKey[flag].arr],
        index,
        {
          name: "",
          statement: "",
        }
      );
    },
    removeArrData(flag, index) {
      const KEY = this.dataKey[flag];
      this[KEY.arr].splice(index, 1);
      this[KEY.num] = this[KEY.arr].length;
    },
    updateArr(flag) {
      const KEY = this.dataKey[flag];
      const ArrLength = this[KEY.arr].length;

      if (this[KEY.num] < 1 || this[KEY.num] > 10) {
        this[KEY.num] = ArrLength;
        return;
      }

      // increase
      if (this[KEY.num] > ArrLength) {
        for (let i = 0; i < this[KEY.num] - ArrLength; i++) {
          setTimeout(() => {
            this[KEY.arr].push({
              name: "",
              statement: "",
            });
          }, 30 * i);
        }
      }
      // decrease
      else if (this[KEY.num] < ArrLength) {
        for (let i = ArrLength; i > this[KEY.num]; i--) {
          this[KEY.arr].splice(this[KEY.arr].length - 1);
        }
      }
    },

    uploadEvidence(e, flag) {
      if (e.target.files.length > 10) {
        document.getElementById(`${flag}vidence`).value = "";
        this.toastError("max-evidence");
        return;
      }

      const Files = Array.from(e.target.files).map(
        (img) => validateInput(img, "file", ["png", "jpeg", "jpg", "pdf"])
      );
      if (Files.findIndex(x => !x[0]) > -1) {
        document.getElementById(`${flag}vidence`).value = "";
        this.toastError("evidence");
      }
    },
  parseCollectionValue(original) {
      return JSON.stringify(
        original.reduce((acc, obj) => {
          acc[obj.name] = obj.statement;
          return acc;
        }, {})
      );
    },
    increment(flag) {
      const KEY = this.dataKey[flag];
      this[KEY.num] = (parseInt(this[KEY.num]) || 0) + 1;
      this.updateArr(flag);
    },
    decrement(flag) {
      const KEY = this.dataKey[flag];
      if (this[KEY.num] > 0) {
        this[KEY.num] = (parseInt(this[KEY.num]) || 0) - 1;
        this.updateArr(flag);
      }
    }, 
   validateWitnessArray(witnessArray) {
  return Array.isArray(witnessArray) && witnessArray.some(witness => 
    witness.name.trim() !== "" && witness.statement.trim() !== ""
  );
},

async handleFileUpload() {
    this.file_in_upload = true;

    try {
      // Check if at least one of the required fields has a value
      if (!(
        this.validateWitnessArray(this.witness_client) ||
        this.validateWitnessArray(this.witness_opposing) ||
        (this.selectedFile && this.selectedFile.size > 0) ||
        document.getElementById("cevidence")?.files.length > 0 ||
        document.getElementById("aevidence")?.files.length > 0
      )) {
        this.toastError("one_value");
        return;
      }

        // Create FormData and append required fields
        const formData = new FormData();
         // Check if there are valid entries in witness_client
        const validWitnessClient = this.witness_client.filter(witness => witness.name.trim() !== "" && witness.statement.trim() !== "");

        // Check if there are valid entries in witness_opposing
        const validWitnessOpposing = this.witness_opposing.filter(witness => witness.name.trim() !== "" && witness.statement.trim() !== "");

        // Append to FormData: If valid entries exist, append them, otherwise append an empty object
        formData.append('witness_client', validWitnessClient.length > 0 ? this.parseCollectionValue(validWitnessClient) : '{}');
        formData.append('witness_opposing', validWitnessOpposing.length > 0 ? this.parseCollectionValue(validWitnessOpposing) : '{}');

        // Append trial document if it exists (Optional)
        if (this.selectedFile && this.selectedFile.size > 0) {
          if (!this.validateFileType(this.selectedFile)) {
            this.toastError("file");
            return;
          }
          formData.append('trial_doc', this.selectedFile);
        }

        // Append client evidence files if provided (Optional)
        const client_evidences = document.getElementById("cevidence")?.files;
        if (client_evidences && client_evidences.length > 0) {
          for (const file of client_evidences) {
            if (file.size > 0 && this.validateFileType(file)) {
              formData.append('evidence_client[]', file);
            } else {
              console.log("Invalid client evidence file or file not selected.");
            }
          }
        }

        // Append opposing evidence files if provided (Optional)
        const adverse_evidences = document.getElementById("aevidence")?.files;
        if (adverse_evidences && adverse_evidences.length > 0) {
          for (const file of adverse_evidences) {
            if (file.size > 0 && this.validateFileType(file)) {
              formData.append('evidence_opposing[]', file);
            } else {
              this.toastError("file");
            }
          }
        }

        // Log all FormData entries for debugging
        // for (let [key, value] of formData.entries()) {
        //   console.log(`FormData Key: ${key}, Value:`, value);
        //   if (key.includes('file')) {
        //     console.log(`File Name: ${value.name}`);
        //   }
        // }

        // Submit form data
        const response = await NewTrialForm.submitOngoing(this.$route.params.id, formData);

        // Check if response is valid
        if (response && response.data && response.data.data) {
          this.$toast.success("Files uploaded successfully.");
          this.$router.push({
            name: "LegalExpertSystemPreTrialResult",
            params: {
              id: response.data.data.id,
              category: "trialDoc",
              complete: "true"
            }
          });
        } else {
          this.$toast.error("Failed to upload files.");
        }
      } catch (error) {
        console.log(error);
        this.$toast.error("Failed to upload files.");
      } finally {
        this.file_in_upload = false;
      }
    }
  },
};
</script>

<style>
.nexcard {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px; */
  /* padding: 1.5rem; */
}

header.card-header {
  padding: 0px !important;
}

.accordion-header {
  background-color: transparent;
  width: 100%;
  height: 100%;
  border-color: transparent;
  color: black;
  text-align: left;
}
.accordion-header:hover {
  background-color: transparent;
}

.accordion-header[aria-expanded="true"] .arrow-icon {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.accordion-header[aria-expanded="false"] .arrow-icon {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.arrow-icon {
  display: none;
  display: block;
  -webkit-transition: transform 0.5s ease;
  -o-transition: transform 0.5s ease;
  transition: transform 0.5s ease;
  color: black;
}

.name {
  display: flex;
  align-items: center;
  padding: 0.4rem;
  flex-grow: 1;
  color: black !important;
}

.redbtn {
  padding-top: 2px;
}

.redbtn:hover {
  color: red;
  background-color: transparent;
}

.bi {
  font-size: 22px;
}

.bi-info-circle {
  width: .75rem;
}

.span_badge {
  border: none;
  color: black;
  border-radius: 20px;
  font-size: 17px;
  padding: 0.1rem 0.2rem;
}
</style>